import { Cancel, CheckCircle, CloudUpload, Error, Upload } from '@mui/icons-material';
import {
	Alert,
	Box,
	Button,
	Chip,
	Divider,
	Grid,
	IconButton,
	InputLabel,
	LinearProgress,
	styled,
	Typography,
	useTheme,
} from '@mui/material';
import { TablePagination } from 'core/components/DataGrid';
import FormSelectField from 'core/components/FormSelectField';
import { useAPI } from 'core/hooks/api';
import { openToast } from 'core/services/toast';
import DocumentsService from 'modules/documents/services/DocumentsService';
import Document, { documentSize, DocumentType, MaxDocumentSize } from 'modules/documents/types/Document';
import React, { useMemo, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import DeleteDocumentDialog from './DeleteDocumentDialog';

const AcceptedFileTypes = {
	'application/pdf': ['.pdf'],
	'image/jpeg': ['.jpg', '.jpeg'],
	'image/png': ['.png'],
	'application/msword': ['.doc'],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
};

export interface DocumentUploaderProps {
	documentTypes: DocumentType[];
	documents: Document[];
	onDelete: (doc: Document) => Promise<void>;
	uploadFile: (file: File, documentType: DocumentType, context: DocumentUploaderContext) => Promise<void>;
}

export interface DocumentUploaderContext {
	setId: (id: string) => void;
	setProgress: (v: number) => void;
}

export default function DocumentUploader({ documentTypes, documents, onDelete, uploadFile }: DocumentUploaderProps) {
	// Hooks
	const { t } = useTranslation('documents');
	const theme = useTheme();

	// State
	const [docType, setDocType] = useState<DocumentType | null>(null);
	const [uploads, setUploads] = useState<UploadFile[]>([]);

	// Dropzone
	const { isDragActive, isDragReject, open, getRootProps, getInputProps } = useDropzone({
		accept: AcceptedFileTypes,
		noClick: true,
		maxSize: MaxDocumentSize,
		onDropAccepted: (files) => {
			if (!docType) return;

			// Store files for upload
			setUploads((prev) => [...files.map((file) => ({ id: fileId(file), file, documentType: docType })), ...prev]);

			// CLEAR-2229: Reset the document type
			setDocType(null);

			// CLEAR-1909: Upload is parent component responsibility
			files.forEach((file) => {
				const context: DocumentUploaderContext = {
					setId: (id) => {
						// Set ID on the file
						setUploads((prev) => prev.map((u) => (u.file === file ? { ...u, id } : u)));
					},
					setProgress: (progress) => {
						// Set progress on the file
						setUploads((prev) => prev.map((u) => (u.file === file ? { ...u, progress } : u)));
					},
				};

				uploadFile(file, docType, context);
			});
		},
		onDropRejected: (files) => {
			if (!docType) return;

			// List failed uploads
			setUploads((prev) => [
				...files.map(({ file, errors }) => ({
					id: fileId(file),
					file,
					documentType: docType,
					// Only show the first error, assume ErrorCode since we don't use custom validation
					error: errors[0].code as ErrorCode,
				})),
				...prev,
			]);
		},
	});

	const style = useMemo(
		() => ({
			...(isDragActive ? { borderColor: theme.palette.primary.main } : {}),
			...(isDragReject ? { borderColor: theme.palette.error.main } : {}),
		}),
		[isDragActive, isDragReject, theme],
	);

	// Computed
	const options = documentTypes.map((type) => ({
		id: type,
		name: t(`types.${type}`),
	}));
	const totalFiles = documents.length + uploads.length;

	return (
		<Grid container spacing={4}>
			<Grid item xs={12} md={5}>
				<FormSelectField
					name="type"
					label={t('uploader.type')}
					options={options}
					value={options.find((option) => option.id === docType) || null}
					onChange={(v) => setDocType(v?.id || null)}
					getOptionLabel={(option) => option.name}
					getOptionKey={(option) => option.id}
					slotProps={{
						autocomplete: {
							disableClearable: true,
							blurOnSelect: true,
						},
					}}
				/>

				<Divider sx={{ my: 2 }} />

				{docType ? (
					<DropzoneBox
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...getRootProps({ style })}
					>
						{/* eslint-disable-next-line react/jsx-props-no-spreading */}
						<input {...getInputProps()} />

						<Typography align="center" variant="subtitle2" display="inline-flex" alignItems="center" gap={0.5}>
							<Upload />
							{t('uploader.title')}
						</Typography>

						<Typography align="center" variant="caption" color={theme.typography.subtitle2.color}>
							{t('uploader.acceptedTypes', {
								types: Object.values(AcceptedFileTypes)
									.map((ss) => ss.map((s) => s.replace('.', '').toUpperCase()).join(', '))
									.join(', '),
							})}
						</Typography>

						<Typography align="center" variant="caption" color={theme.typography.subtitle2.color}>
							{t('uploader.maxSize', { maxSize: documentSize(MaxDocumentSize, false) })}
						</Typography>

						<Button variant="outlined" color="primary" startIcon={<CloudUpload />} onClick={open}>
							{t('buttons.upload', { ns: 'core' })}
						</Button>
					</DropzoneBox>
				) : (
					<Alert severity="info">{t('uploader.selectType')}</Alert>
				)}
			</Grid>

			{/* Documents list */}
			{totalFiles > 0 && (
				<UploadedFiles
					documents={documents}
					uploads={uploads}
					onDelete={async (doc) => {
						// If this was uploaded (id is UUID), delete it
						if (doc.id.length === 36) await onDelete(doc);

						// If this was an upload, remove it
						setUploads((prev) => prev.filter((u) => u.id !== doc.id));
					}}
				/>
			)}
		</Grid>
	);
}

interface UploadedFilesProps {
	documents: Document[];
	uploads: UploadFile[];
	onDelete: DocumentUploaderProps['onDelete'];
}

function UploadedFiles({ documents, uploads, onDelete }: UploadedFilesProps) {
	// Hooks
	const { t } = useTranslation('documents');

	// State
	const [deleteDocument, setDeleteDocument] = useState<Document | null>(null);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	// Computed
	const completedUploads = uploads
		// Filter out any uploads that have been uploaded fully and converted to documents
		.filter((u) => !documents.some((d) => d.id === u.id));

	const uploadedFiles = documents.length + completedUploads.filter(({ progress }) => progress === 100).length;
	const totalFiles = documents.length + completedUploads.length;

	// Pagination
	type Doc = Document & {
		rowType: 'doc';
	};

	type Upload = UploadFile & {
		rowType: 'upload';
	};

	type DocOrUpload = Doc | Upload;

	const paginated: DocOrUpload[] = [
		...completedUploads.map((upload): Upload => ({ rowType: 'upload', ...upload })),
		...documents.map((doc): Doc => ({ rowType: 'doc', ...doc })),
	].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	return (
		<Grid item xs={12} md={7}>
			<InputLabel sx={{ mb: 1 }}>{t('upload.files.title', { uploaded: uploadedFiles, count: totalFiles })}</InputLabel>

			<Grid display="grid" gridTemplateColumns="auto 1fr 60px" gap={0.5} alignItems="center">
				{paginated.map((item, idx) => {
					if (item.rowType === 'upload')
						return (
							<UploadingFile
								key={item.id}
								id={item.id}
								file={item.file}
								documentType={item.documentType}
								progress={item.progress}
								error={item.error}
								divider={idx < paginated.length - 1}
								onRemove={() => {
									const uploadAsDoc: Document = {
										id: item.id.toString(),
										name: item.file.name,
										size: item.file.size,
										contentType: item.file.type,
										type: { code: item.documentType },
										tracking: { createdBy: '', createdDate: '' },
									};

									// If item has an error, remove immediately
									if (item.error) {
										onDelete(uploadAsDoc);
										return;
									}

									// Mock the upload as a document
									setDeleteDocument(uploadAsDoc);
								}}
							/>
						);

					return (
						<DocumentFile
							key={item.id}
							doc={item}
							divider={idx < paginated.length - 1}
							onRemove={() => setDeleteDocument(item)}
						/>
					);
				})}
			</Grid>

			<TablePagination
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				component="div"
				count={totalFiles}
				page={page}
				onPageChange={(_, p) => setPage(p)}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={[5, 10, 25]}
				onRowsPerPageChange={(e) => {
					setRowsPerPage(parseInt(e.target.value, 10));
					setPage(0);
				}}
			/>

			<DeleteDocumentDialog
				document={deleteDocument}
				isOpen={!!deleteDocument}
				setIsOpen={() => setDeleteDocument(null)}
				onConfirm={async () => {
					if (!deleteDocument) return;
					await onDelete(deleteDocument);
					setDeleteDocument(null);
				}}
			/>
		</Grid>
	);
}

interface CommonFileProps {
	divider: boolean;
	onRemove?: () => void;
}

const LinearProgressMatchedDivider = styled(Divider)({
	gridColumn: 'span 3',
	height: '4px',
	boxSizing: 'border-box',
	marginTop: '-2px',
	marginBottom: '2px',
});

const FileName = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	textAlign: 'left',
	display: 'inline',
	wordBreak: 'break-word',
	lineHeight: theme.spacing(2.5).toString(),
}));

const ProgressOrFileSize = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	whiteSpace: 'nowrap',
	lineHeight: 'unset',
	color: theme.palette.grey[600],
}));

const openInNewTab = (url: string): void => {
	window.open(url, '_blank', 'noopener,noreferrer');
};

function DocumentFile({ doc, divider, onRemove }: CommonFileProps & { doc: Document }) {
	const { t } = useTranslation('documents');
	const documentsService = useAPI(DocumentsService);

	const downloadFile = async () => {
		const url = await documentsService.downloadDocument(doc.id);
		// let api handle error
		openInNewTab(url);
	};

	return (
		<React.Fragment key={doc.id}>
			<Chip label={t(`types.${doc.type.code}`)} />

			<Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" gap={0.5}>
				<FileName onClick={downloadFile}>{doc.name}</FileName>
				<ProgressOrFileSize variant="caption">({documentSize(doc.size)})</ProgressOrFileSize>
			</Box>

			<Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
				<CheckCircle color="success" fontSize="small" />
				<IconButton size="small" onClick={onRemove}>
					<Cancel color="disabled" fontSize="small" />
				</IconButton>
			</Box>

			{divider && <LinearProgressMatchedDivider />}
		</React.Fragment>
	);
}

interface UploadFile {
	id: string;
	file: File;
	documentType: DocumentType;
	progress?: number;
	error?: ErrorCode;
}

function UploadingFile({ id, file, documentType, progress, error, divider, onRemove }: CommonFileProps & UploadFile) {
	const { t } = useTranslation('documents');

	const uploading = progress !== 100;

	const icon = (() => {
		if (error) return <Error color="error" fontSize="small" />;
		if (uploading) return <ProgressOrFileSize variant="caption">{progress || 0}%</ProgressOrFileSize>;

		return <CheckCircle color="success" fontSize="small" />;
	})();

	const documentsService = useAPI(DocumentsService);

	const downloadFile = async () => {
		if (uploading) return;
		const url = await documentsService.downloadDocument(id, true).catch(() => {
			openToast({
				id: `download-document-dialog:${id}`,
				message: t('toasts.fileNotAvailable'),
				severity: 'info',
			});
		});
		if (!url) return;
		openInNewTab(url);
	};

	return (
		<React.Fragment key={id}>
			<Chip label={t(`types.${documentType}`)} />

			<Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" gap={0.5}>
				<FileName onClick={downloadFile}>{file.name}</FileName>
				<ProgressOrFileSize variant="caption">({documentSize(file.size)})</ProgressOrFileSize>
			</Box>

			<Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
				{icon}
				<IconButton size="small" onClick={onRemove}>
					<Cancel color="disabled" fontSize="small" />
				</IconButton>
			</Box>

			{/* In progress */}
			{uploading && !error && (
				<LinearProgress variant="determinate" value={progress || 0} sx={{ gridColumn: 'span 3' }} />
			)}

			{/* Error */}
			{error && (
				<Box gridColumn="span 3">
					<LinearProgress variant="determinate" color="error" value={1} />
					<Typography variant="caption" color="error">
						{t(`uploader.errors.${error}`, {
							defaultValue: t('uploader.errors.general'),
							maxSize: documentSize(MaxDocumentSize, false),
						})}
					</Typography>
				</Box>
			)}

			{divider && !uploading && <LinearProgressMatchedDivider />}
		</React.Fragment>
	);
}

const DropzoneBox = styled(Box)(({ theme }) => ({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: theme.spacing(2),
	borderWidth: 2,
	borderRadius: 2,
	borderColor: theme.palette.divider,
	borderStyle: 'dashed',
	boxSizing: 'border-box',
	backgroundColor: '#fafafa',
	color: theme.typography.body1.color,
	outline: 'none',
	transition: theme.transitions.create('border'),
	gap: theme.spacing(1),
}));

const fileId = (file: File) => (Date.now() + file.size).toString() + file.name;
